import { styles } from '@/styles/styles'
import { css, useTheme } from '@emotion/react'
import React, { HTMLAttributes } from 'react'
// @ts-ignore
import decorationBubble from '@/images/decoration_bubble-01.svg'
// @ts-ignore
import decorationBubbleSp from '@/images/decoration_bubble-01-sp.svg'
import { InViewFadeInUp } from './fade-in'

export function ThreeStagesBlockHeading({
  children,
  ...props
}: HTMLAttributes<HTMLHeadingElement>) {
  const theme = useTheme()
  return (
    <InViewFadeInUp className="three-stage-heading">
      <h2
        css={css`
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 8.2rem;
          font-weight: ${theme.fontWeights.bold};
          letter-spacing: ${theme.letterSpacings.relaxed};
          text-align: center;
          background: url(${decorationBubbleSp}) no-repeat top center / contain;

          @media ${theme.breakpoints.md} {
            height: 10.2rem;
            font-size: ${theme.fontSizes.heading3};
            background: url(${decorationBubble}) no-repeat top center / contain;
          }
        `}
        {...props}
      >
        <span
          css={css`
            transform: translateY(-30%);

            & > strong {
              font-size: ${theme.fontSizes.heading3};
            }

            @media ${theme.breakpoints.md} {
              transform: translateY(-20%);

              & > strong {
                margin: 0 0.2rem;
                font-family: ${theme.fonts.roboto};
                font-size: ${theme.fontSizes.heading1};
                vertical-align: -3%;
              }
            }
          `}
        >
          {children}
        </span>
      </h2>
    </InViewFadeInUp>
  )
}

export function ThreeStagesBlockBody(props: HTMLAttributes<HTMLDivElement>) {
  const theme = useTheme()

  return (
    <InViewFadeInUp>
      <p
        css={[
          styles.textCenter,
          css`
            margin-top: 3rem;
            font-weight: ${theme.fontWeights.bold};
            letter-spacing: ${theme.letterSpacings.tight};
            line-height: ${theme.lineHeight.looser};

            @media ${theme.breakpoints.md} {
            }

            & > span {
              display: block;

              &:not(:first-of-type) {
                margin-top: 3rem;
              }
            }

            & > span > span {
              ${[
                styles.inlineBlock,
                css`
                  font-size: ${theme.fontSizes.body1};
                  border-bottom: solid 0.5rem ${theme.colors.yellow.light};

                  @media ${theme.fontSizes.body0} {
                    font-size: ${theme.fontSizes.body0};
                  }
                `,
              ]}
            }
          `,
        ]}
        {...props}
      />
    </InViewFadeInUp>
  )
}

export function StageBlock(props: HTMLAttributes<HTMLDivElement>) {
  const theme = useTheme()

  return (
    <InViewFadeInUp>
      <div
        css={css`
          margin-top: 10rem;

          @media ${theme.breakpoints.md} {
            margin-top: 14rem;
          }

          h4 {
            margin-top: 4rem;
            font-size: ${theme.fontSizes.body0};
            font-weight: ${theme.fontWeights.bold};
          }

          ol {
            margin-top: 0.6rem;
            margin-left: 3ch;
            font-size: 2.1rem;
            line-height: ${theme.lineHeight.relaxed};
            list-style: decimal;
          }

          p {
            margin-top: 2rem;

            &.text-en {
              letter-spacing: ${theme.letterSpacings.tighter};
            }

            em {
              position: relative;
              display: block;
              margin-left: 2ch;
              font-size: 2rem;

              &::before {
                content: '＊';
                position: absolute;
                top: 0;
                left: -2ch;
              }
            }
          }

          ul {
            margin-left: 2ch;
            font-size: 2rem;
            list-style: '・';
          }
        `}
        {...props}
      />
    </InViewFadeInUp>
  )
}

export function StageBlockHeader({
  color = 'green',
  lang = 'ja',
  ...props
}: HTMLAttributes<HTMLDivElement> & {
  color: 'green' | 'yellow' | 'magenta'
  lang?: 'ja' | 'en'
}) {
  const theme = useTheme()

  return (
    <div
      css={[
        css`
          color: ${theme.colors[color].main};
          padding-bottom: 1.2rem;
          border-bottom: solid 1px;

          & > h3 {
            display: grid;
            grid-template-columns: 9.5rem 1fr;
            align-items: end;
            gap: 1.6rem;
            font-size: ${theme.fontSizes.heading3};
            font-weight: ${theme.fontWeights.bold};
            letter-spacing: ${theme.letterSpacings.ultratight};
            line-height: 0.8;

            & img {
              grid-row: span 2;
              width: 9.5rem;
            }

            & span {
              display: inline-block;
            }

            & strong {
              margin: 0 0.4rem;
              font-family: ${theme.fonts.roboto};
              font-size: 6.5rem;
              vertical-align: -5%;
            }

            & small {
              display: inline-block;
              font-size: ${theme.fontSizes.body1};
              font-weight: ${theme.fontWeights.medium};
              line-height: ${theme.lineHeight.tighter};
            }

            @media ${theme.breakpoints.md} {
              grid-template-columns: 9.5rem auto 1fr;

              & img {
                grid-row: span 1;
                width: 9.5rem;
                margin-right: 2rem;
              }
            }
          }
        `,
        lang === 'ja' &&
          css`
            white-space: nowrap;
          `,
      ]}
      {...props}
    />
  )
}
